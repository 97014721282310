import AuthPrompt from "../../common/authPrompt";
import docReady from "../../common/docReady";
import buybackReact from '../buyback/index_react';
import { formNameSearch, sectorIndustry, datePicker, formBehavior, formSearchParams, formShowLoading } from "../screener/utils";

const form = () => {
  let isSearching = false;
  formNameSearch();
  sectorIndustry();
  datePicker();
  formBehavior();
  document.querySelector('#form-search')?.addEventListener('click', (e) => {
    if (isSearching) { return; }
    isSearching = true;
    e.preventDefault();
    const url = formSearchParams(window.location.origin + window.location.pathname + 'screener').url;
    formShowLoading();
    window.location.href = url;
    isSearching = false;
  });
}

const buyback = () => {
  buybackReact();
}

docReady(() => {
  document.getElementById('alert_link')?.addEventListener('click', (e) => {
    const isAuth = document.getElementById('data_id')?.dataset['auth'] == 'true';
    if (isAuth) { return; }

    e.preventDefault();
    (new AuthPrompt(AuthPrompt.Context.dashboard)).show();
  });
  form();
  buyback();
});
